/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { forwardRef, Fragment } from 'react';

import { jsx } from '@compiled/react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next/src';
import Button, { IconButton } from '@atlaskit/button/new';
import { cssMap } from '@atlaskit/css';
import type { IconProps, UNSAFE_NewIconProps } from '@atlaskit/icon';
import ChatIcon from '@atlaskit/icon/core/ai-chat';
import AppSwitcherIcon from '@atlaskit/icon/core/app-switcher';
import SearchIcon from '@atlaskit/icon/core/search';
import MenuIcon from '@atlaskit/icon/core/sidebar-expand';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import type { LogoProps } from '@atlaskit/logo';
import { Anchor, Inline, Pressable, Text } from '@atlaskit/primitives';
import { Show } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

import { List } from '../../components/list';
import { ListItem } from '../../components/list-item';

const styles = cssMap({
	commonActionsContainer: {
		display: 'flex',
		gap: token('space.100'),
		width: '100%',
		gridColumn: 2,
		justifyContent: 'end',
		'@media (min-width: 30rem)': {
			justifyContent: 'center',
		},
	},
	searchButtonRoot: {
		gridTemplateColumns: 'auto 1fr auto',
		alignItems: 'center',
		background: token('color.background.input'),
		borderRadius: token('border.radius.100'),
		boxSizing: 'border-box',
		cursor: 'text',
		display: 'none',
		height: '32px',
		maxWidth: '680px',
		paddingBlock: token('space.0'),
		paddingInline: token('space.075'),
		width: '100%',
		'&:hover': {
			background: token('color.background.input.hovered'),
		},
		'&:active': {
			background: token('color.background.input.pressed'),
		},
		'@media (min-width: 30rem)': {
			display: 'grid',
		},
	},
	searchButtonText: {
		gridColumn: '1 / -1',
		gridRow: 1,
	},
	searchIconBefore: {
		display: 'flex',
		gridColumn: 1,
		gridRow: 1,
	},
	searchElemAfter: {
		display: 'flex',
		gridColumn: 3,
		gridRow: 1,
	},
	mobileUserActions: {
		background: 'transparent',
	},
	userActions: {
		gridColumn: 3,
		display: 'flex',
		justifyContent: 'end',
	},
	userActionsList: {
		alignItems: 'center',
		display: 'flex',
		gap: token('space.050'),
	},
	homeActions: {
		alignItems: 'center',
		display: 'flex',
		gap: token('space.050'),
		gridColumn: 1,
	},
});

const navLogoStyles = cssMap({
	root: {
		display: 'flex',
		alignItems: 'center',
		height: '32px',
		borderRadius: token('border.radius.100'),
	},
	icon: {
		display: 'flex',
		'@media (min-width: 64rem)': {
			display: 'none',
		},
	},
	logo: {
		display: 'none',
		'@media (min-width: 64rem)': {
			display: 'flex',
		},
	},
});

/**
 * __Nav logo__
 *
 * The product logo for the top navigation.
 */
export const NavLogo = ({
	href,
	logo: Logo,
	icon: Icon,
	onClick,
	label = 'Home',
}: {
	label?: string;
	href: string;
	logo: (props: LogoProps) => JSX.Element;
	icon: (props: LogoProps) => JSX.Element;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}) => (
	<Anchor aria-label={label} href={href} xcss={navLogoStyles.root} onClick={onClick}>
		<div css={navLogoStyles.icon}>
			<Icon size="small" appearance="brand" label="" />
		</div>
		<div css={navLogoStyles.logo}>
			<Logo size="small" appearance="brand" label="" />
		</div>
	</Anchor>
);

type AppSwitcherProps = {
	label: string;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
};

/**
 * __App switcher__
 *
 * The app switcher button for the top navigation. Allows users to switch between Atlassian products.
 */
export const AppSwitcher = ({
	label = 'App switcher',
	onClick,
	interactionName,
}: AppSwitcherProps) => (
	<IconButton
		icon={AppSwitcherIcon}
		label={label}
		appearance="subtle"
		onClick={onClick}
		interactionName={interactionName}
		isTooltipDisabled={false}
	/>
);

type MobileHamburgerProps = {
	label: string;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
};

/**
 * __Mobile hamburger__
 *
 * TODO: check if this is still needed and remove
 */
export const MobileHamburger = ({ label, onClick, interactionName }: MobileHamburgerProps) => (
	<Show below="sm">
		<IconButton
			icon={MenuIcon}
			label={label}
			appearance="subtle"
			onClick={onClick}
			interactionName={interactionName}
		/>
	</Show>
);

/**
 * __Home actions__
 *
 * Wrapper for the top navigation actions on the inline-start (left) side of the top navigation.
 */
export const HomeActions = ({ children }: { children: React.ReactNode }) => (
	<div css={styles.homeActions}>{children}</div>
);

/**
 * __Search__
 *
 * The search element for the top navigation.
 */
export const Search = ({
	label,
	onClick,
	iconBefore: IconBefore = SearchIcon,
	elemAfter,
	interactionName,
}: {
	label: string;
	iconBefore?: React.ComponentType<UNSAFE_NewIconProps>;
	elemAfter?: React.ReactNode;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
}) => (
	<Fragment>
		<Pressable
			style={{
				// To win the specificity war against Emotion we move this into inline styles
				// When Emotion has been stripped from the Design System move this to Compiled.
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				cursor: 'text',
				border: `${token('border.width')} solid ${token('color.border.input')}`,
			}}
			onClick={onClick}
			xcss={styles.searchButtonRoot}
			interactionName={interactionName}
		>
			<span css={styles.searchIconBefore}>
				<IconBefore color={token('color.icon.subtle')} spacing="spacious" label="" />
			</span>
			<div css={styles.searchButtonText}>
				<Text color="color.text.subtlest">{label}</Text>
			</div>
			{elemAfter && <span css={styles.searchElemAfter}>{elemAfter}</span>}
		</Pressable>
		<Show below="xs">
			<IconButton
				label={label}
				appearance="subtle"
				icon={SearchIcon}
				onClick={onClick}
				interactionName={interactionName}
			/>
		</Show>
	</Fragment>
);

type CommonActionsProps = {
	children: React.ReactNode;
};

/**
 * __Common actions__
 *
 * Wrapper for the actions in the middle of the top navigation.
 */
export const CommonActions = ({ children }: CommonActionsProps) => (
	<div css={styles.commonActionsContainer}>{children}</div>
);

type ChatButtonProps = {
	children: React.ReactNode;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	isSelected?: boolean;
	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
};

/**
 * __Chat button__
 *
 * The chat button for the top navigation.
 * TODO: check if this is still needed and remove. Jira doesn't use it - there's a separate button for ConversationAssistant
 */
export const ChatButton = ({ children, onClick, isSelected, interactionName }: ChatButtonProps) => (
	<ListItem>
		<Button
			appearance="default"
			iconBefore={ChatIcon}
			onClick={onClick}
			isSelected={isSelected}
			interactionName={interactionName}
		>
			{children}
		</Button>
	</ListItem>
);

/**
 * __User actions__
 *
 * Wrapper for the top navigation actions on the inline-end (right) side of the top navigation.
 */
export const UserActions = ({
	children,
	label = 'Actions',
}: {
	children: React.ReactNode;
	label?: string;
}) => (
	<nav aria-label={label} css={styles.userActions}>
		<List xcss={styles.userActionsList}>{children}</List>
	</nav>
);

type UserActionProps = {
	children: React.ReactNode;
	icon: React.ComponentType<IconProps | UNSAFE_NewIconProps>;
	onClick?: (e: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
	isSelected?: boolean;
	testId?: string;
	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
};

/**
 * __User action__
 *
 * A user action button for the top navigation. Used for adding custom actions that are not already provided.
 *
 * For common actions, like `Notifications`, `Help`, `Profile`, `Settings`, use the provided components as appropriate.
 */
export const UserAction = forwardRef<HTMLButtonElement, UserActionProps>(
	(
		{ children, icon, onClick, isSelected, testId, interactionName }: UserActionProps,
		forwardedRef,
	) => (
		<ListItem>
			<IconButton
				ref={forwardedRef}
				icon={icon}
				label={children}
				onClick={onClick}
				appearance="subtle"
				isSelected={isSelected}
				isTooltipDisabled={false}
				testId={testId}
				interactionName={interactionName}
			/>
		</ListItem>
	),
);

type AvatarDropdownActionProps = UserActionProps & { children: string };

/**
 * __Avatar dropdown action__
 *
 * A user action that displays a dropdown on small viewports.
 */
export const AvatarDropdownAction = ({
	children,
	onClick,
	icon: Icon,
	interactionName,
}: AvatarDropdownActionProps) => (
	<Fragment>
		<UserAction icon={Icon} onClick={onClick} interactionName={interactionName}>
			{children}
		</UserAction>
		<Show below="sm">
			<Pressable
				onClick={onClick}
				aria-label={children}
				xcss={styles.mobileUserActions}
				interactionName={interactionName}
			>
				<Inline alignBlock="center" space="space.100">
					<Icon label="" />
					<ChevronDownIcon label="" color={token('color.icon', '#44546F')} />
				</Inline>
			</Pressable>
		</Show>
	</Fragment>
);

/**
 * We are flattening the top navigation root into the page layout top bar component
 * to support the visual refresh. This isn't needed anymore and will be cleaned up soon.
 *
 * @private
 * @deprecated
 */
export const TopNavigation = ({ children }: { children: React.ReactNode }) => (
	<Fragment>{children}</Fragment>
);
